import 'bootstrap/dist/css/bootstrap.min.css';

import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import config from '../../config.json'
import Marquee from 'react-fast-marquee'
import { toggleCollapse } from '../scripts/scripts'

import '../scss/pages/index.scss'
import MainSectionImage from '../assets/images/persons.png';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton/SecondaryButton'
import InfoBox from '../components/InfoBox/InfoBox'
import Posts from '../components/Posts/Posts'

import NationaleNedarlanden from '../assets/images/ic/nationale-nederlanden.png'
import Aegon from '../assets/images/ic/aegon.png'
import Aviva from '../assets/images/ic/aviva.png'
import Generali from '../assets/images/ic/generali.png'
import Pzu from '../assets/images/ic/pzu.png'
import Warta from '../assets/images/ic/warta.png'
import Uniqa from '../assets/images/ic/uniqa.png'

import Movie from '../assets/video/film_ubezpieczenie_do_kredytu.mp4';
import MovieThumbnail from '../assets/images/ubezpieczenie-do-kredytu-kadr-z-filmu.png'
import SeeMovie from '../assets/images/see-movie.svg'
import Person from '../assets/images/icons/person.svg'
import Bank from '../assets/images/icons/bank.svg'
import Childs from '../assets/images/icons/childs.svg'
import Family from '../assets/images/icons/family.svg'
import Money from '../assets/images/icons/money.svg'
import Choice from '../assets/images/choice.svg'
import PrimaryArrow from '../assets/images/icons/primary-arrow.svg'
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react'

const IndexPage = () => {
  const data = useStaticQuery(query);
  const seo = data.wpPage.seo;
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  
  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    const video = document.getElementById('videoPlayer');

    if(!video) return;

    if(show){
      video.play();
    }
    else{
      video.currentTime = 0;
      video.pause();
    }
  })

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/" />
      <div className="main-section position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-xs-12">
              <span className="small-header secondary-text-color">Dobierz ubezpieczenie do kredytu</span>
              <h1 className="title">Ubezpieczenie na życie do kredytu hipotecznego <br /><span className="secondary-text-color">nawet 60% taniej!</span></h1>
              <p class="paragraph mb-0 primary-text-color">Porównaj oferty nawet 12 Towarzystw Ubezpieczeniowych <br />w zaledwie 2 minuty!</p>
              <div className="btn-group mt-30">
                <PrimaryButton link={config.CALC_LINK} title="Uzyskaj wycenę polisy" icon="pocket-icon" />
                <SecondaryButton link="/ranking-ubezpieczen/" title="Sprawdź ranking" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center">
              <img src={MainSectionImage} className="main-img" alt="Ubezpieczenie do kredytu" title="Ubezpieczenie do kredytu" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h2 className="header-h2 black-text-color text-center">U nas porównasz oferty Towarzystw:</h2>
              <div className="ic-carousel mx-auto display-block mt-30">
                <Marquee>
                  <img width="200" src={NationaleNedarlanden} alt="Nationale Nederlanden" title="Nationale Nederlanden" />
                  <img width="200" src={Aegon} alt="Aegon" title="Aegon" />
                  <img width="200" src={Aviva} alt="Aviva" title="Aviva" />
                  <img width="200" src={Generali} alt="Generali" title="Generali" />
                  <img width="200" src={Pzu} alt="Pzu" title="Pzu" />
                  <img width="200" src={Warta} alt="Warta" title="Warta" />
                  <img width="200" src={Uniqa} alt="Uniqa" title="Uniqa" />
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="insurance-benefits section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-xs-12">
              <span className="small-header white-text-color">Korzyści ubezpieczenia</span>
              <h3 className="header-h3 white-text-color mt-15 mb-30">Czy ubezpieczenie na życie daje mi jakąkolwiek korzyść?</h3>
              <p className="paragraph white-text-color mb-0">Ubezpieczenie na życie jest <strong className="font-bold">jedyną</strong> ochroną dla Twojego małżonka w razie gdyby Ciebie zabrakło, a takżewtedy kiedy przydarzy się bardzo poważna choroba, która uniemożliwia Ci pracę.</p>
              <p className="paragraph white-text-color mt-30 mb-0">Polisa na życie do kredytu hipotecznego to teraz rozwiązanie:</p>
              <ul>
                <li><i className="check-icon"></i>niedrogie</li>
                <li><i className="check-icon"></i>proste</li>
                <li><i className="check-icon"></i>gwarantowane</li>
              </ul>
              <p className="paragraph white-text-color mt-30 mb-0">W razie poważnych sytuacji życiowych rodzina otrzyma <strong className="font-bold">wysoką wypłatę pieniędzy</strong>. Porównaj oferty ubezpieczeń i dobierz niedrogie ubezpieczenie na wysoką sumę.</p>
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12">
              <img src={SeeMovie} className="see-movie" alt="Zobacz video" title="Zobacz video" draggable={false} />
              <img src={MovieThumbnail} className="movie" alt="Zobacz wideo" title="Zobacz video" onClick={handleShow} />
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video controls preload="none" id="videoPlayer">
                  <source src={Movie} type="video/mp4" />
                  Przeglądarka nie wspiera odtwarzania plików wideo.
                </video>
              </Modal.Body>
            </Modal>

          </div>
        </div>
      </div>
      <div className="how-it-work section-padding">
        <div className="custom-container container">
          <div className="row">
            <div className="col-md-12 mb-60">
              <p className="small-header secondary-text-color text-center mb-0">Jak to działa?</p>
              <h3 className="header-h3 text-center mt-15 mb-30">Jak działa porównanie <span className="secondary-text-color">ofert ubezpieczenia</span> <br />na życie pod kredyt?</h3>
              <p className="paragraph text-center primary-text-color">Dowiedz się jak ubezpiecza się kredyt hipoteczny <br />i jak uniknąć najczęstszych błędów.</p>
            </div>
          </div>
          <div className="row display-flex">
            <div className="col-md-6 position-relative">
              <img src={PrimaryArrow} className="primary-arrow" draggable={false} alt="Porównanie ofert z Doradcą" title="Porównanie ofert z Doradcą" />
              <div className="box">
                <img src={Person} className="icon" draggable={false} alt="Porównanie ofert z doradcą" title="Porównanie ofert z doradcą" />
                <h4 className="header-h4 secondary-text-color text-center mt-15 mb-15">Porównanie <br />ofert z Doradcą</h4>
                <p className="paragraph text-center primary-text-color">Skupiamy się na potrzebach ubezpieczeniowych wyłącznie, szukając dopasowanej oferty</p>

                <ul>
                  <li>
                    <div className="number">1</div>
                    <p className="row-title">Analiza potrzeb <br />ubezpieczeniowych</p>
                    <p className="paragraph text-center primary-text-color">Wypełniamy ankietę naszych potrzeb, żeby polisa chroniła tylko to, co bezwarunkowo musi</p>
                  </li>
                  <li>
                    <div className="number">2</div>
                    <p className="row-title">Przedstawienie <br />konkurencyjnych ofert</p>
                    <p className="paragraph text-center primary-text-color">Sprawdzamy kilka rodzajów ochrony, ceny miesięczne składki, wyłączenia</p>
                  </li>
                  <li>
                    <div className="number">3</div>
                    <p className="row-title">Wybór dopasowanego <br />ubezpieczenia</p>
                    <p className="paragraph text-center primary-text-color">Negocjujemy wybrany wariant, upewniamy się u Doradcy czy dobrze rozumiemy zapisy OWU</p>
                  </li>
                </ul>
                <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="box box-blur">
                <img src={Bank} className="icon" draggable={false} alt="Tradycyjny wniosek w banku" title="Tradycyjny wniosek w banku" />
                <h4 className="header-h4 secondary-text-color text-center mt-15 mb-15">Tradycyjny <br />wniosek w banku</h4>
                <p className="paragraph text-center primary-text-color">Zależy nam na kredycie hipotecznym, a nie na ubezpieczeniu na życie - nie po to jest bank!</p>

                <ul>
                  <li>
                    <div className="number">1</div>
                    <p className="row-title">Zbieranie dokumentacji <br />do kredytu</p>
                    <p className="paragraph text-center primary-text-color">Działamy pod presją czasu, często wybieramy kredyt po raz pierwszy w życiu</p>
                  </li>
                  <li>
                    <div className="number">2</div>
                    <p className="row-title">Tylko jedna propozycja <br />ubezpieczenia</p>
                    <p className="paragraph text-center primary-text-color">Nie rozważamy alternatywnych dróg, nie analizujemy ich, zależy nam na kredycie</p>
                  </li>
                  <li>
                    <div className="number">3</div>
                    <p className="row-title">Ubezpieczenia na życie <br />"z biegu"</p>
                    <p className="paragraph text-center primary-text-color">Godzimy się na warunki, bo brzmią rozsądnie z promocją np. na niższą marżę, ale promocja minęła, a mi nadal płacimy...</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="calculator section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12 mb-60">
              <p className="small-header secondary-text-color text-center mb-0">Jak to działa?</p>
              <h3 className="header-h3 text-center mt-15 mb-30">Jak działa <span className="secondary-text-color">kalkulator ubezpieczenia</span> <br />na życie do kredytu hipotecznego?</h3>
              <h4 className="header-h4 secondary-text-color text-center mb-30">Wybór sumy ubezpieczenia na życie</h4>
              <p className="paragraph text-center primary-text-color">Suma ubezpieczenia to pieniądze, które zostaną wypłacone rodzinie lub Tobie w przypadku wystąpienia specjalnego zdarzenia. To zdarzenie musi być dokładnie opisane w polisie. Przykłady zdarzeń w ubezpieczeniu na życie, które skutkują wypłatą pieniędzy:</p>
            </div>

            <div className="col-md-12">
              <div className="c-box">
                <img src={Bank} className="c-icon" alt="Śmierć, z cesją na bank" title="Śmierć, z cesją na bank" draggable={false} />
                <div className="content">
                  <h5 className="header-h5">Śmierć, <span className="text-decoration-underline secondary-text-color">z cesją na bank</span></h5>
                  <p className="paragraph primary-text-color"><span className="font-bold">W wariancie minimum</span> ubezpieczenie na życie do kredytu hipotecznego z cesją na bank, to tylko liczba - wysokość składki. Poza tym nic nie powinno nas interesować i niczego nie powinniśmy oczekiwać po polisie poza spłatą tego konkretnego kredytu. Dlaczego?</p>
                </div>
              </div>
              <div className="show-more-content mb-30">
                <p className="paragraph mb-0 primary-text-color hidden-content d-none">Suma ubezpieczenia jest jednocześnie wysokością kredytu hipotecznego pozostałego do spłaty. Taka polisa to minimum, a jednocześnie konieczność, aby spłacić kredyt za rodzinę, która inaczej zostałaby z tym sama.</p>
                <div class="paragraph show-more text-center" onClick={toggleCollapse}></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="c-box">
                <img src={Family} className="c-icon" alt="Śmierć, bez cesji na bank" title="Śmierć, bez cesji na bank" draggable={false} />
                <div className="content">
                  <h5 className="header-h5">Śmierć, <span className="text-decoration-underline secondary-text-color">bez cesji na bank</span></h5>
                  <p className="paragraph primary-text-color"><span className="font-bold">W wariancie rozszerzonym</span> polisa na życie do kredytu daje nam coś więcej niż samą spłatę kredytu hipotecznego (czy innego dużego zobowiązania). Daje decyzyjność. Małżonek, który zostaje sam lub z nami, ale my wymagamy poważnej opieki, decyduje, czy pokryć z polisy koszt kredytu czy np. wydatki zdrowotne.</p>
                </div>
              </div>
              <div className="show-more-content">
                <p className="paragraph mb-0 primary-text-color hidden-content d-none">Małżonek lub partnerzy mogą podjąć decyzję np. o kontynuowaniu spłat rat na dotychczasowych zasadach, a pieniądze wyłożyć na leczenie. Można także sprzedać nieruchomość i wybrać inny sposób zagospodarowania pieniędzy. Może to być niebagatelna korzyść dla osób, które aktywnie zarządzają kapitałem z korzyścią dla siebie, dzieci, rodziny.</p>
                <div class="paragraph show-more text-center" onClick={toggleCollapse}></div>
              </div>
            </div>
          </div>
        </div>

        <InfoBox />

        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <div className="c-box">
                <img src={Money} className="c-icon" alt="Utrata przychodów" title="Utrata przychodów" draggable={false} />
                <div className="content">
                  <h5 className="header-h5"><span className="secondary-text-color">Utrata</span> przychodów</h5>
                  <p className="paragraph primary-text-color">Jest rozumiana w OWU jako trwała lub czasowa niezdolność do podjęcia pracy zarobkowej. Oznacza to w praktyce dla Ubezpieczonego, że jego stan zdrowia jest tak zły, że nie może pracować <span className="font-bold">wcale</span>, nawet jeśli sytuacja występuje czasowo, choćby 12 miesięcy</p>
                </div>
              </div>
              <div className="show-more-content mb-30">
                <p className="paragraph mb-0 primary-text-color hidden-content d-none">Przykładem może być ciężki wypadek, po którym Ubezpieczony praktycznie tylko rehabilituje się lub pozostaje pod opieką bliskich lub placówki medycznej i otrzymuje od ZUS stosowne oświadczenie, że podjęcie przez niego pracy jest niemożliwe. Świadczenie z tytułu utraty dochodu można otrzymać czasowo, np. na rok w formie renty 3000 zł, albo w formie wypłaty jednorazowej, np. 200.000 zł z powodu znaczego uszczerbku na zdrowiu.</p>
                <div class="paragraph show-more text-center" onClick={toggleCollapse}></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="c-box">
                <img src={Childs} className="c-icon" alt="Przyszłość dzieci" title="Przyszłość dzieci" draggable={false} />
                <div className="content">
                  <h5 className="header-h5"><span className="secondary-text-color">Przyszłość</span> dzieci</h5>
                  <p className="paragraph primary-text-color">Polisa na życie, przez zaplanowanie wyższej sumy ubezpieczenia lub dobranie dodatkowych umów, może zapewnić pieniądze na pokrycie potrzeb dzieci. Że te potrzeby rosną, nie musimy się przekonywać. A zebranie znaczącej sumy pieniędzy jako dodatek często przekracza nasz budżet.</p>
                </div>
              </div>
              <div className="show-more-content">
                <p className="paragraph mb-0 primary-text-color hidden-content d-none">Przy ubezpieczeniu na życie do kredytu hipotecznego warto przeprowadzić dodatkową kalkulację, przykładowo jeśli mamy dwoje dzieci i chcemy, aby każde z nich, w razie gdyby nas już nie było, otrzymało po 50.000 zł na start w przyszłość, sumę 100.000 zł należy doliczyć do całkowitej sumy ubezpieczenia. Podsumowując, suma ubezpieczenia na życie powinna uwzględnić kredyt i dodatkowe potrzeby.</p>
                <div class="paragraph show-more text-center" onClick={toggleCollapse}></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="choice">
        <div className="duration section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-12 col-xs-12">
                <h3 className="header-h3 text-white">Wybór okresu trwania polisy</h3>
                <p className="paragraph text-white mt-30 mb-0">Najczęściej zawierane polisy do kredytu hipotecznego trwają 15, 20, 25, 30 i 35 lat, bo to najczęstsze okresy kredytowania. Pamiętajmy, że ubezpieczenie na życie do kredytu hipotecznego można zawrzeć <span className="font-bold">na czas trwania kredytu lub dłuższy</span> - wtedy <span className="font-bold">polisa będzie chronić po okresie spłaty swoją sumą ubezpieczenia rodzinę, a nie bank</span>. Posiadanie polisy na życie z myślą o rodzinie jest przezornością, która zwraca się w najtrudniejszych chwilach: śmierci, wypadku, poważnej choroby, utraty możliwości zarabiania.</p>
              </div>
            </div>
          </div>
        </div>
        <img className="choice-image" src={Choice} alt="Wybór okresu trwania polisy i uposażonego" title="Wybór okresu trwania polisy i uposażonego" draggable={false} />
        <div className="vested section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-12 col-xs-12">
                <h3 className="header-h3 black-text-color">Wybór Uposażonego</h3>
                <p className="paragraph black-text-color mt-30 mb-0"><span className="font-bold">To decyzja strategiczna</span> i jeśli tylko mamy możliwość - jeśli dopuszcza ją bank - lepiej uposażyć żonę lub partnera, aby mogli podjąć właściwą dla danej chwili decyzję. Jeśli natomiast nie chcemy zostawić tej decyzji partnerowi z obawy choćby o to, że w danym momencie nie w głowie mu będzie dysponowanie setkami tysięcy złotych - co zrozumiałe - uposażmy bank, a i tak, jeśli suma ubezpieczenia przewyższy kredyt pozostały do spłaty, <span className="font-bold">środki trafią po zaspokojeniu sie banku na polisie, do naszych bliskich</span>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choice-of-insuracne section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="header-h3 black-text-color text-center mb-30">Wybór odpowiedniego ubezpieczyciela</h3>
            </div>
            <div className="col-md-6 column">
              <p className="paragraph black-text-color text-center paragraph-height font-light">Kalkulowanie cen ubezpieczenia i wybór właściwej polisy na życie do kredytu to zadanie dla nas na tu i teraz. Odkładanie tej decyzji oznacza w praktyce trzy proste i niemiłe rzeczy:</p>

              <div className="choice-box mb-25">
                <div className="choice-icon xmark-choice-icon"></div>
                <div className="choice-content xmark-bg">
                  <p className="paragraph text-white mb-0">kredyt hipoteczny pozostanie niezabezpieczony i ciężar spłat przejdzie na bliskich,</p>
                </div>
              </div>
              <div className="choice-box mb-25">
                <div className="choice-icon xmark-choice-icon"></div>
                <div className="choice-content xmark-bg">
                  <p className="paragraph text-white mb-0">kredyt hipoteczny będzie ubezpieczony polisą niedopasowaną do tego, np. taką z naszej pracy i zabraknie na niej środków na całkowitą spłatę, wtedy patrz pkt 1.</p>
                </div>
              </div>
              <div className="choice-box">
                <div className="choice-icon xmark-choice-icon"></div>
                <div className="choice-content xmark-bg">
                  <p className="paragraph text-white mb-0">przepłacimy za rozwiązanie, które mamy teraz, bo np. było w pakiecie z kredytem i dajemy za nie 65% więcej niż jest potrzeba.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 column">
              <p className="paragraph black-text-color text-center paragraph-height font-light">Sprawdzenie ofert 4 ubezpieczycieli to kilka minut dziennie na zarządzenie:</p>

              <div className="choice-box mb-25">
                <div className="choice-icon check-choice-icon"></div>
                <div className="choice-content check-bg">
                  <p className="paragraph text-white mb-0">Ilością wariantów jakie chcemy otrzymać</p>
                </div>
              </div>
              <div className="choice-box mb-25">
                <div className="choice-icon check-choice-icon"></div>
                <div className="choice-content check-bg">
                  <p className="paragraph text-white mb-0">Określeniem ryzyk do polisy (czy coś poza kredytem ubezpieczamy?)</p>
                </div>
              </div>
              <div className="choice-box mb-25">
                <div className="choice-icon check-choice-icon"></div>
                <div className="choice-content check-bg">
                  <p className="paragraph text-white mb-0">Otwartością na jedną wideorozmowę z podpisaniem wniosku do polisy na życie</p>
                </div>
              </div>
              <p className="paragraph black-text-color text-center font-light">Nic więcej nie jest potrzebne, aby zoptymalizować ubezpieczenie na życie do kredytu. To jest banalnie proste i oszczędność rzędu 50%, 60% jest jak najbardziej możliwa.</p>
            </div>
            <div className="col-md-12">
              <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
            </div>
          </div>
        </div>
      </div>
      <Posts />
    </Layout>
  )
}

export default IndexPage

const query = graphql`
  {
    wpPage(slug: {eq: "strona-glowna"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`
